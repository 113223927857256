import React from 'react';
import { Link } from 'gatsby';
import { FormattedIcon } from '@components/icons';
import config from '@utils/config';
import Logo from '@images/logos/logo-fill.png';

// styles
import { Container } from '@styles';
import {
  StyledFooter,
  StyledCopy,
  FooterWrapper,
  WidgetTitle,
  WidgetDescription,
  StyledLink,
  LinksFlex,
  StyledLogo,
  IconWrapper,
} from './styles';

const Footer = () => {
  var d = new Date();
  var n = d.getFullYear();

  return (
    <>
      <StyledFooter>
        <Container normal>
          <FooterWrapper>
            <div>
              <WidgetTitle>Useful Links</WidgetTitle>
              <LinksFlex>
                <StyledLink
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="http://www.absolutefencinggear.com"
                >
                  Absolute fencing
                </StyledLink>
                <StyledLink
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="http://www.usafencing.org"
                >
                  USA Fencing
                </StyledLink>
                <StyledLink
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="http://www.askfred.net"
                >
                  AskFred
                </StyledLink>
                <StyledLink
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="http://www.fie.org"
                >
                  FIE
                </StyledLink>
              </LinksFlex>
            </div>
            <div>
              <Link to="/">
                <StyledLogo src={Logo} alt="Logo" />
              </Link>
              <WidgetDescription>
                Train hard, train smart, never give up.
              </WidgetDescription>
              {config.socialMedia &&
                config.socialMedia.map((media, i) => (
                  <IconWrapper
                    key={i}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    href={media.url}
                  >
                    <FormattedIcon name={media.name} />
                  </IconWrapper>
                ))}
            </div>
            <div>
              <WidgetTitle>Alex Kushkov</WidgetTitle>
              <WidgetDescription>
                Come try fencing and get inspired to learn at Dynamo Fencing
                Center!
              </WidgetDescription>
            </div>
          </FooterWrapper>
        </Container>
      </StyledFooter>
      <StyledCopy>
        <p>
          Copyright © {n} Dynamo Fencing Center |{' '}
          <Link to="/privacy">Privacy Policy</Link>
        </p>
      </StyledCopy>
    </>
  );
};

export default Footer;
