import React from 'react';
import SimpleReactLightbox from 'simple-react-lightbox';

//styles
import { GlobalStyle } from '@styles';

//components
import Nav from '@components/nav';
import Head from './Head';
import CTA from '@components/cta';
import Partners from '@components/partners';
import Footer from '@components/footer';
import config from '@utils/config';

const metadata = {
  title: config.siteTitle,
  siteUrl: config.siteUrl,
  description: config.siteDescription,
};

export default ({ children }) => {
  return (
    <SimpleReactLightbox>
      <Head metadata={metadata} />
      <GlobalStyle />
      <Nav />
      {children}
      <CTA />
      <Partners />
      <Footer />
    </SimpleReactLightbox>
  );
};
