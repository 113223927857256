import styled from 'styled-components';
import { Link } from 'gatsby';
import { theme } from '@styles';

const { fonts, colors } = theme;

export const A = styled.a`
  display: flex;
  align-items: center;
  flex: none;
`;

export const StyledLink = styled(Link)`
  display: flex;
  flex: none;
  align-items: center;
`;

export const StyledButton = styled.button`
  border-radius: 0;
  font-family: ${fonts.Secondary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1;
  min-width: 170px;
  padding: 22px 39px;
  text-align: center;
  text-transform: uppercase;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
`;

export const StyledPrimaryButton = styled(StyledButton)`
  background-color: transparent;
  border: 2px solid ${colors.accent};
  color: ${colors.text};
  padding: 21px 39px;

  :hover {
    color: #000;
    background-color: ${colors.accent};
  }
`;

export const StyledSecondaryButton = styled(StyledButton)`
  background-color: ${colors.secondary};
  border: 2px solid ${colors.accent};
  color: #000;
  padding: 21px 39px;

  :hover {
    color: #000;
    background-color: ${colors.accent};
  }
`;
