const theme = {
  colors: {
    main: '#00358e',
    secondary: '#a4dbe8',
    accent: '#f8e59a',
    dark: '#001e52',
    text: '#ffffff',
    hollow: '#0a0d1b',
    hollowRGBA: 'rgba(10, 13, 27, 0.8)',
    light: '#a4a9b9',
  },

  fonts: {
    Primary: "'montserrat', sans-serif",
    Secondary: "'roboto', sans-serif",
  },
};

export default theme;
