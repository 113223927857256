import styled from 'styled-components';
import { PrimaryButton } from '@components/button';
import { theme, media } from '@styles';

const { colors } = theme;

export const StyledCTA = styled.section`
  width: 100%;
  height: auto;
  padding: 2rem 0;
  background-color: ${colors.main};
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  grid-gap: 5rem;
  align-items: center;

  ${media.desktop`grid-gap: 3rem;`};
  ${media.tablet`
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1rem;
    text-align: center;
  `};
`;

export const Title = styled.h4`
  color: ${colors.text};
  margin: 0;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  ${media.tablet`width: 100%;`};
`;
