import styled from 'styled-components';
import { theme, media } from '@styles';

const { colors } = theme;

export const StyledPartners = styled.section`
  width: 100%;
  height: auto;
  padding: 2rem 0;
  background-color: ${colors.hollowRGBA};
`;

export const PartnerContent = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  align-items: center;
  grid-gap: 6rem;
  ${media.desktop`grid-gap: 2rem;`};
  ${media.thone`
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 2rem;
  `};
`;

export const StyledPartnerLogo = styled.img`
  filter: invert(50%);
  transition: all 0.15s ease-in-out 0s;
  width: 100%;

  :hover {
    transform: scale(1.1);
  }
`;
