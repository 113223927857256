module.exports = [{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"dynamofencing","accessToken":"MC5YdWxoVFJRQUFMQUZvaVAx.X--_ve-_ve-_ve-_ve-_ve-_ve-_vU4v77-977-9aUHvv702SVXvv73vv71-77-977-9egxi77-977-9P23vv71z","path":"/preview","previews":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dynamo Fencing Center","short_name":"starter","start_url":"/","background_color":"#00358e","theme_color":"#00358e","display":"minimal-ui","icon":"src/images/favicon/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"889db2e547cfcb3b4cefbcb3500663de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-148219790-1","head":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
