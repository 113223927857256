import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import media from './media';

const { colors, fonts } = theme;

// https://github.com/necolas/normalize.css
const normalize = `
  html{line-height:1.15;-webkit-text-size-adjust:100%}body{margin:0}main{display:block}h1{font-size:2em;margin:.67em 0}hr{box-sizing:content-box;height:0;overflow:visible}pre{font-family:monospace,monospace;font-size:1em}a{background-color:transparent}abbr[title]{border-bottom:none;text-decoration:underline;text-decoration:underline dotted}b,strong{font-weight:bolder}code,kbd,samp{font-family:monospace,monospace;font-size:1em}small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}sub{bottom:-.25em}sup{top:-.5em}img{border-style:none}button,input,optgroup,select,textarea{font-family:inherit;font-size:100%;line-height:1.15;margin:0}button,input{overflow:visible}button,select{text-transform:none}[type=button],[type=reset],[type=submit],button{-webkit-appearance:button}[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner{border-style:none;padding:0}[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring{outline:1px dotted ButtonText}fieldset{padding:.35em .75em .625em}legend{box-sizing:border-box;color:inherit;display:table;max-width:100%;padding:0;white-space:normal}progress{vertical-align:baseline}textarea{overflow:auto}[type=checkbox],[type=radio]{box-sizing:border-box;padding:0}[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}[type=search]{-webkit-appearance:textfield;outline-offset:-2px}[type=search]::-webkit-search-decoration{-webkit-appearance:none}::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}details{display:block}summary{display:list-item}template{display:none}[hidden]{display:none}
`;

const GlobalStyle = createGlobalStyle`
  ${normalize};

  * {
    box-sizing: border-box;
  }

  html {
    height: 100%;
    overflow: visible;
    position: relative;
    width: 100%;
  }

  ::-moz-selection {
    background-color: ${colors.accent};
    color: #fff;
  }

  ::selection {
    background-color: ${colors.accent};
    color: #fff;
  }

  ::-moz-selection {
    background-color: ${colors.accent};
    color: #fff;
  }

  body {
    background-color: ${colors.dark};
    font-family: ${fonts.Primary};
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    overflow: visible;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${fonts.Secondary};
    font-weight: 400;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    word-break: break-word;
  }

  h1 {
    font-size: 56px;
    line-height: 1;
    margin-bottom: 0.45em;
    margin-top: 1.15em;
  }

  h2 {
    font-size: 48px;
    line-height: 1;
    margin-bottom: 0.55em;
    margin-top: 0.9em;
  }

  h3 {
    font-size: 40px;
    line-height: 1;
    margin-bottom: 0.65em;
    margin-top: 1.2em;
  }

  h4 {
    font-size: 32px;
    line-height: 1;
    margin-bottom: 0.9em;
    margin-top: 1.5em;
  }

  h5 {
    font-size: 24px;
    line-height: 1;
    margin-bottom: 1.2em;
    margin-top: 1.9em;
  }

  h6 {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 1.8em;
    margin-top: 2.8em;
  }

  ${media.thone`
  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  h4 {
    font-size: 1.3rem;
  }

  h5 {
    font-size: 1.2rem;
  }

  h6 {
    font-size: 1.1rem;
  }
  `}

  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child {
    margin-top: 0;
  }

  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child,
  h6:last-child {
    margin-bottom: 0;
  }

  p {
    margin: 0 0 20px;
  }

  ul:last-child,
  ol:last-child,
  p:last-child {
    margin-bottom: 0;
  }

  p:last-child:after {
  clear: both;
  content: '';
  display: block;
}

  img,
  figure {
    height: auto;
    max-width: 100%;
  }

  figcaption {
    font-size: 0.95em;
    line-height: 1.4;
    padding: 10px 0 7px;
  }

  figcaption p {
    margin-bottom: 0;
  }

  b,
  strong {
    font-weight: 700;
  }

  label {
    color: ${colors.headings};
  }

  a {
    color: ${colors.headings};
    text-decoration: none;
    transition: all 0.2s ease-in-out 0s;
  }

  a:hover {
    color: ${colors.main};
    text-decoration: none;
    transition: all 0.15s linear 0s;
  }

  blockquote {
    border: none;
    color: #a4a9b9;
    font-size: 20px;
    font-style: italic;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 45px;
    max-width: 720px;
    padding: 0;
    position: relative;
    text-align: center;
  }

  blockquote .media {
    margin: 15px 0;
  }

  blockquote .media img {
    border-radius: 50%;
    max-width: 60px;
  }

  blockquote h3,
  blockquote h4,
  blockquote h5,
  blockquote .text-small {
    font-style: normal;
    margin-bottom: 8px;
    margin-top: 28px;
    text-transform: none;
  }

  input:focus,
  button:focus,
  select:focus,
  textarea:focus,
  a:focus {
    outline: medium none;
    text-decoration: none;
  }

  a > img {
    transition: all 0.15s ease-in-out 0s;
  }

  a:hover > img {
    opacity: 0.8;
  }

  a.btn:active,
  a.button:active,
  button:active,
  input[type='submit']:active {
    position: relative;
    top: 1px !important;
  }

  hr {
    border-color: #e1e1e1;
    margin-bottom: 30px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 30px;
  }

  iframe {
    border: none;
    max-width: 100%;
  }

  table {
    margin: 10px 0;
    max-width: 100%;
    width: 100%;
  }

  table td,
  table th {
    line-height: 1.42857143;
    padding: 15px 8px 15px 8px;
    text-align: center;
    vertical-align: middle;
  }

  table th {
    color: ${colors.headings};
    font-weight: normal;
    vertical-align: middle;
  }

  canvas {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  
  .BrainhubCarousel__arrows {
    background-color: ${colors.main}!important;

    :hover {
      background-color: ${colors.main}!important;
    }
  }

  .aws-btn {
    --slider-height-percentage: 100%;
    --slider-transition-duration: 575ms;
    --organic-arrow-thickness: 4px;
    --organic-arrow-border-radius: 0px;
    --organic-arrow-height: 40px;
    --organic-arrow-color: #6a6a6a;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: #6a6a6a;
    --control-bullet-active-color: #6a6a6a;
    --loader-bar-color: #851515;
    --loader-bar-height: 6px;
  }
`;

export default GlobalStyle;
