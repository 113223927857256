import React, { Component } from 'react';
import Logo from '@images/logos/logo-fill.png';
import { Link } from 'gatsby';
import { throttle } from '@utils';
import config from '@utils/config';
import Menu from './menu';

// styles
import styled from 'styled-components';
import { PrimaryButton } from '@components/button';
import { Container, theme, media } from '@styles';

const { colors, fonts } = theme;

const ContainerWrapper = styled.header`
  background-color: ${colors.main};
  transition: all 0.2s ease;
  padding: ${(props) => (props.isAtTop ? '20px 0' : '8px 0')};
  position: sticky;
  z-index: 999;
  top: 0;
`;
const NavWrapper = styled.div`
  display: flex;
  min-height: 5rem;
  align-items: center;
`;
const StyledNav = styled.nav`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
`;
const Hamburger = styled.div`
  overflow: visible;
  margin: 0 -12px 0 0;
  padding: 15px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
  display: none;
  z-index: 1000;
  ${media.bigDesktop`display: flex;`};
`;
const HamburgerBox = styled.div`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 24px;
`;
const HamburgerInner = styled.div`
  background-color: ${colors.text};
  position: absolute;
  width: 20px;
  height: 3px;
  border-radius: 3px;
  top: 50%;
  left: 0;
  right: 0;
  transition-duration: 0.22s;
  transition-property: transform;
  transition-delay: ${(props) => (props.menuOpen ? `0.12s` : `0s`)};
  transform: rotate(${(props) => (props.menuOpen ? `225deg` : `0deg`)});
  transition-timing-function: cubic-bezier(
    ${(props) =>
      props.menuOpen ? `0.215, 0.61, 0.355, 1` : `0.55, 0.055, 0.675, 0.19`}
  );
  &:before,
  &:after {
    content: '';
    display: block;
    background-color: ${colors.text};
    position: absolute;
    left: auto;
    right: 0;
    width: 20px;
    height: 3px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    border-radius: 4px;
  }
  &:before {
    width: ${(props) => (props.menuOpen ? `100%` : `120%`)};
    top: ${(props) => (props.menuOpen ? `0` : `-10px`)};
    opacity: ${(props) => (props.menuOpen ? 0 : 1)};
    transition: ${(props) =>
      props.menuOpen
        ? 'top 0.1s ease-out, opacity 0.1s ease-out 0.12s'
        : 'top 0.1s ease-in 0.25s, opacity 0.1s ease-in'};
  }
  &:after {
    width: ${(props) => (props.menuOpen ? `100%` : `80%`)};
    bottom: ${(props) => (props.menuOpen ? `0` : `-10px`)};
    transform: rotate(${(props) => (props.menuOpen ? `-90deg` : `0`)});
    transition: ${(props) =>
      props.menuOpen
        ? 'bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s'
        : 'bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)'};
  }
`;
const SectionLeft = styled.div`
  display: flex;
  align-items: center;
  flex: auto;
  height: 100%;
`;
const SectionCenter = styled.div`
  display: flex;
  align-items: center;
  flex: auto;
  height: 100%;
  justify-content: center;
`;
const SectionRight = styled.div`
  display: flex;
  align-items: center;
  flex: auto;
  height: 100%;
  justify-content: flex-end;
`;
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const StyledLogo = styled.img`
  display: inline-block;
  max-height: 80px;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: none;
`;
const ListWrapper = styled.ol`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  ${media.bigDesktop`display: none;`};
`;
const ListItem = styled.li`
  margin: 0 0.5rem;
  position: relative;
  list-style: none;
`;
const MobileHideWrapper = styled(ListItem)`
  ${media.tablet`display: none;`};
`;
const NavLink = styled(Link)`
  font-family: ${fonts.Secondary};
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 14px;
  padding: 33px 0;
  margin: 0 25px;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: color, background-color, border-color;
  zoom: 1;
  color: ${colors.text};
  :hover {
    color: ${colors.accent};
  }
  ${media.giant`
    margin: 0 15px;
    padding: 53px 0;
  `};
`;
const NavText = styled.div`
  font-family: ${fonts.Secondary};
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 14px;
  padding: 33px 0;
  margin: 0 25px;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: color, background-color, border-color;
  zoom: 1;
  color: ${colors.text};
  cursor: pointer;
  :hover {
    color: ${colors.accent};
  }
  ${media.giant`
    margin: 0 15px;
    padding: 53px 0;
  `};
`;
const DropDownMenu = styled.ul`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 90%;
  z-index: 1000;
  background-color: ${colors.main};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  list-style: none;
  margin-top: 5px;
  min-width: 15em;
  padding: 15px;
  text-align: center;
`;
const DropDownItem = styled.li`
  padding-left: 30px;
  padding-right: 30px;
  white-space: nowrap;
  display: list-item;

  :last-child a {
    border-bottom: none;
  }
`;
const DropDownLink = styled(NavLink)`
  padding-left: 40px;
  padding-right: 40px;
  color: ${colors.text};
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: block;
`;

class Nav extends Component {
  state = {
    menuOpen: false,
    aboutDrop: false,
    mediaDrop: false,
    programsDrop: false,
    isAtTop: true,
  };

  componentDidMount() {
    setTimeout(() => {
      window.addEventListener('resize', () => throttle(this.handleResize()));
      window.addEventListener('keydown', (e) => this.handleKeydown(e));
      window.addEventListener('scroll', () => this.handleScroll());
    }, 100);
  }

  toggleMenu = () => this.setState({ menuOpen: !this.state.menuOpen });

  handleResize = () => {
    if (window.innerWidth > 768 && this.state.menuOpen) {
      this.toggleMenu();
    }
  };

  handleScroll = () => {
    if (window.scrollY > 1) {
      this.setState({ isAtTop: false });
    } else {
      this.setState({ isAtTop: true });
    }
  };

  handleKeydown = (e) => {
    if (!this.state.menuOpen) {
      return;
    }

    if (e.which === 27 || e.key === 'Escape') {
      this.toggleMenu();
    }
  };

  render() {
    const {
      menuOpen,
      isAtTop,
      aboutDrop,
      mediaDrop,
      programsDrop,
    } = this.state;

    return (
      <ContainerWrapper isAtTop={isAtTop}>
        <Container normal>
          <NavWrapper>
            <StyledNav>
              <SectionLeft>
                <LogoWrapper>
                  <Link to="/">
                    <StyledLogo src={Logo} />
                  </Link>
                </LogoWrapper>
              </SectionLeft>
              <SectionCenter>
                <ListWrapper>
                  {config.navLinks &&
                    config.navLinks.map(({ url, links, name }, i) => (
                      <ListItem key={i}>
                        {url && <NavLink to={url}>{name}</NavLink>}
                        {!url && (
                          <>
                            {i === 0 && (
                              <NavText
                                onMouseEnter={() =>
                                  this.setState({
                                    aboutDrop: true,
                                  })
                                }
                                onMouseLeave={() =>
                                  this.setState({
                                    aboutDrop: false,
                                  })
                                }
                              >
                                {name}
                                <DropDownMenu isOpen={aboutDrop}>
                                  {links.map(({ title, url }, i) => (
                                    <DropDownItem key={i}>
                                      <DropDownLink to={url}>
                                        {title}
                                      </DropDownLink>
                                    </DropDownItem>
                                  ))}
                                </DropDownMenu>
                              </NavText>
                            )}
                            {i === 1 && (
                              <NavText
                                onMouseEnter={() =>
                                  this.setState({
                                    programsDrop: true,
                                  })
                                }
                                onMouseLeave={() =>
                                  this.setState({
                                    programsDrop: false,
                                  })
                                }
                              >
                                {name}
                                <DropDownMenu isOpen={programsDrop}>
                                  {links.map(({ title, url }, i) => (
                                    <DropDownItem key={i}>
                                      <DropDownLink to={url}>
                                        {title}
                                      </DropDownLink>
                                    </DropDownItem>
                                  ))}
                                </DropDownMenu>
                              </NavText>
                            )}
                            {i === 2 && (
                              <NavText
                                onMouseEnter={() =>
                                  this.setState({
                                    mediaDrop: true,
                                  })
                                }
                                onMouseLeave={() =>
                                  this.setState({
                                    mediaDrop: false,
                                  })
                                }
                              >
                                {name}
                                <DropDownMenu isOpen={mediaDrop}>
                                  {links.map(({ title, url }, i) => (
                                    <DropDownItem key={i}>
                                      <DropDownLink to={url}>
                                        {title}
                                      </DropDownLink>
                                    </DropDownItem>
                                  ))}
                                </DropDownMenu>
                              </NavText>
                            )}
                          </>
                        )}
                      </ListItem>
                    ))}
                </ListWrapper>
              </SectionCenter>
              <SectionRight>
                <MobileHideWrapper>
                  <PrimaryButton
                    href="https://dynamo-fencing.mylessonschedule.com/Identity/Account/Login"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    Members Login
                  </PrimaryButton>
                </MobileHideWrapper>
                <Hamburger onClick={this.toggleMenu}>
                  <HamburgerBox>
                    <HamburgerInner menuOpen={menuOpen} />
                  </HamburgerBox>
                </Hamburger>
              </SectionRight>
            </StyledNav>
            <Menu menuOpen={menuOpen} toggleMenu={this.toggleMenu} />
          </NavWrapper>
        </Container>
      </ContainerWrapper>
    );
  }
}

export default Nav;
