import React from 'react';
import Absolute from '@images/partners/absolute-fencing.png';
import AFA from '@images/partners/afa.png';
import Beaver from '@images/partners/beaver.png';
import IslandFencing from '@images/partners/island-fencing-academy.png';
import Shaloh from '@images/partners/shaloh.png';
import StamfordFencing from '@images/partners/stamford-fencing-center.png';

// styles
import { Container } from '@styles';
import { StyledPartners, PartnerContent, StyledPartnerLogo } from './styles';

const partnersList = [
  { id: 0, href: 'https://www.islandfencingacademy.com' },
  {
    id: 1,
    href:
      'https://bcdschool.org/athletics/teams-and-schedules/fencing-us-varsity-1',
  },
  {
    id: 2,
    href: 'https://www.absolutefencinggear.com',
  },
  {
    id: 3,
    href: 'http://www.shaloh.org/templates/preschool/article_cdo/aid/2345879',
  },
  {
    id: 4,
    href: 'http://stamfordfencingcenter.com',
  },
  {
    id: 5,
    href: 'http://stamfordfencingcenter.com/afa',
  },
];

const Partners = () => {
  return (
    <StyledPartners>
      <Container normal>
        <PartnerContent>
          {partnersList.map((reference, i) => (
            <a
              key={i}
              target="_blank"
              rel="nofollow noopener noreferrer"
              href={reference.href}
            >
              <StyledPartnerLogo
                style={i === 5 ? { filter: 'grayscale(100%)' } : {}}
                src={
                  i === 0
                    ? IslandFencing
                    : i === 1
                    ? Beaver
                    : i === 2
                    ? Absolute
                    : i === 3
                    ? Shaloh
                    : i === 4
                    ? StamfordFencing
                    : AFA
                }
                alt="Logo"
              />
            </a>
          ))}
        </PartnerContent>
      </Container>
    </StyledPartners>
  );
};

export default Partners;
