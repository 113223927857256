import React from 'react';

// styles
import { Container } from '@styles';
import {
  StyledCTA,
  ContentWrapper,
  Title,
  StyledPrimaryButton,
} from './styles';

const CTA = () => {
  return (
    <StyledCTA>
      <Container normal>
        <ContentWrapper>
          <Title>Ready to start fencing? Schedule today!</Title>
          <StyledPrimaryButton to="/contact">
            Talk to us now
          </StyledPrimaryButton>
        </ContentWrapper>
      </Container>
    </StyledCTA>
  );
};

export default CTA;
