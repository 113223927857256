import styled from 'styled-components';
import { theme, media } from '@styles';

const { colors, fonts } = theme;

export const StyledFooter = styled.footer`
  width: 100%;
  height: auto;
  padding: 2rem 0;
  background: ${colors.hollow};
`;

export const StyledCopy = styled.small`
  background-color: ${colors.dark};
  text-align: center;

  p {
    font-family: ${fonts.Secondary};
    color: ${colors.text};
    padding: 0.8rem 0;

    a {
      text-decoration: none;
      opacity: 1 !important;
      color: ${colors.accent};
    }
  }
`;

export const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 2rem;
  align-items: center;
  text-align: center;

  ${media.tablet`
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  `};
`;

export const WidgetTitle = styled.h3`
  padding-bottom: 22px;
  position: relative;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1;
  color: ${colors.text};

  :after {
    content: '';
    background-color: ${colors.accent};
    bottom: 0;
    height: 3px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 50px;
  }
`;

export const WidgetDescription = styled.p`
  color: ${colors.light};
  margin: 0 0 20px;
`;

export const StyledLink = styled.a`
  color: ${colors.text};
  text-decoration: none;
  transition: all 0.2s ease-in-out 0s;

  :hover {
    color: ${colors.accent};
  }
`;

export const LinksFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLogo = styled.img`
  max-height: 80px;
  max-width: 100%;
`;

export const IconWrapper = styled.a`
  border-color: ${colors.text};
  color: ${colors.text};
  padding: 1rem;

  svg {
    height: 2em;
    line-height: 2.8em;
    width: 2em;
  }
`;
