import React from 'react';
import PropTypes from 'prop-types';
import IconPhone from './phone';
import IconClock from './clock';
import IconPin from './pin';
import IconMail from './mail';
import IconArrowLeft from './arrow-left';
import IconArrowRight from './arrow-right';
import IconQuote from './quote';
import IconFacebook from './facebook';
import IconYelp from './yelp';
import IconInstagram from './instagram';
import IconChevron from './chevron';
import IconPlane from './plane';
import IconStar from './star';
import IconRocket from './rocket';

const FormattedIcon = ({ name }) => {
  switch (name) {
    case 'phone':
      return <IconPhone />;
    case 'clock':
      return <IconClock />;
    case 'pin':
      return <IconPin />;
    case 'mail':
      return <IconMail />;
    case 'arrow-right':
      return <IconArrowRight />;
    case 'arrow-left':
      return <IconArrowLeft />;
    case 'quote':
      return <IconQuote />;
    case 'facebook':
      return <IconFacebook />;
    case 'yelp':
      return <IconYelp />;
    case 'instagram':
      return <IconInstagram />;
    case 'chevron':
      return <IconChevron />;
    case 'rocket':
      return <IconRocket />;
    case 'star':
      return <IconStar />;
    case 'plane':
      return <IconPlane />;
    default:
      return null;
  }
};

FormattedIcon.propTypes = {
  name: PropTypes.string.isRequired || PropTypes.object.isRequired,
};

export default FormattedIcon;
