import React from 'react';
import { Link } from 'gatsby';
import config from '@utils/config';

// styles
import styled from 'styled-components';
import { PrimaryButton } from '@components/button';
import { theme, media } from '@styles';

const { colors, fonts } = theme;

const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  outline: 0;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: translateX(${(props) => (props.menuOpen ? 0 : 100)}vw);
  visibility: ${(props) => (props.menuOpen ? 'visible' : 'hidden')};
  display: none;
  ${media.bigDesktop`display: block;`};
`;
const Sidebar = styled.aside`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${colors.main};
  padding: 50px;
  width: 50vw;
  height: 100%;
  position: relative;
  right: 0;
  margin-left: auto;
  font-family: ${fonts.Secondary};
  box-shadow: -10px 0px 30px -15px rgba(0, 0, 0, 0.6);
  ${media.thone`padding: 25px;`};
  ${media.phablet`width: 75vw;`};
  /* ${media.tiny`padding: 10px;`}; */
`;
const NavLinks = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: column;
  text-align: center;
  color: ${colors.text};
`;
const NavList = styled.ol`
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
`;
const NavListItem = styled.li`
  margin: 0 auto 20px;
  position: relative;
  ${media.thone`
    margin: 0 auto 10px;
  `};
`;
const NavLink = styled(Link)`
  font-family: ${fonts.Secondary};
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 14px;
  padding: 33px 0;
  margin: 0 25px;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: color, background-color, border-color;
  zoom: 1;
  color: ${colors.text};
  :hover {
    opacity: 1;
    color: ${colors.accent};
  }
  ${media.giant`
    margin: 0 15px;
    padding: 53px 0;
  `};
  ${media.thone`font-size: 17px;`};
  ${media.phone`font-size: 16px;`};
`;
const NavText = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${fonts.Secondary};
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.8px;
  padding: 13px 0;
  line-height: 14px;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: color, background-color, border-color;
  zoom: 1;
  color: ${colors.text};
  ${media.giant`
    margin: 0 15px;
    padding: 10px 0;
  `};
  ${media.thone`font-size: 17px;`};
  ${media.phone`font-size: 16px;`};
`;
const SmallerNavLink = styled(NavLink)`
  opacity: 0.6;
  padding: 0 !important;
  font-size: 14px !important;
  padding-top: 8px !important;
`;
const StyledPrimaryButton = styled(PrimaryButton)`
  display: none;
  margin-top: 20px;
  width: 100%;
  ${media.tablet`display: block;`};
`;

const Menu = ({ menuOpen, toggleMenu }) => {
  const handleMenuClick = (e) => {
    const target = e.target;
    const isLink = target.hasAttribute('href');
    const isNotMenu =
      target.classList && target.classList[0].includes('StyledContainer');

    if (isLink || isNotMenu) {
      toggleMenu();
    }
  };

  return (
    <StyledContainer
      menuOpen={menuOpen}
      onClick={handleMenuClick}
      aria-hidden={!menuOpen}
      tabIndex={menuOpen ? 1 : -1}
    >
      <Sidebar>
        <NavLinks>
          <NavList>
            {config.navLinks &&
              config.navLinks.map(({ url, links, name }, i) => (
                <NavListItem key={i}>
                  {url && <NavLink to={url}>{name}</NavLink>}
                  {!url && (
                    <>
                      <NavText>
                        {name}

                        {links.map(({ title, url }, i) => (
                          <SmallerNavLink to={url} key={i}>
                            {title}
                          </SmallerNavLink>
                        ))}
                      </NavText>
                    </>
                  )}
                </NavListItem>
              ))}
            <NavListItem>
              <StyledPrimaryButton
                href="https://dynamo-fencing.mylessonschedule.com/Identity/Account/Login"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                Members Login
              </StyledPrimaryButton>
            </NavListItem>
          </NavList>
        </NavLinks>
      </Sidebar>
    </StyledContainer>
  );
};

export default Menu;
