import styled from 'styled-components';
import theme from './theme';

const { colors, fonts } = theme;

const NormalText = styled.p`
  font: ${fonts.Secondary};
  color: ${colors.light};

  ul {
    padding-inline-start: 20px;
  }

  a {
    color: ${colors.text};
    text-decoration: none;

    :hover {
      color: ${colors.accent};
    }
  }
`;

export default NormalText;
