module.exports = {
  siteTitle: 'Dynamo Fencing Center',
  siteDescription:
    'Located in Newton Center, the Dynamo was founded by Alex Kushkov and start operating in 2014. A couple years later it’s youth program began to bear its first fruits and become one of the best in the country. We love Fencing!',
  siteKeywords:
    'dynamo fencing center, fencing, absolute fencing, sabre, saber, foil, epee, fencing, newton fencing, fencing club, fencing center',
  siteUrl: 'https://dynamo-fencing.com',
  siteLanguage: 'en_US',
  name: 'Dynamo Fencing Center',
  email: 'dynamo.fencing.center@gmail.com',
  socialMedia: [
    {
      name: 'instagram',
      url: 'https://www.instagram.com/dynamo.fencing.center',
    },
    {
      name: 'facebook',
      url: 'https://www.facebook.com/DynamoFencingCenter',
    },
    {
      name: 'yelp',
      url: 'https://www.yelp.com/biz/dynamo-fencing-center-newton-2',
    },
  ],
  navLinks: [
    {
      name: 'About',
      links: [
        {
          title: 'Coaches',
          url: '/coaches',
        },
        {
          title: 'Results',
          url: '/results',
        },
        {
          title: 'FAQ',
          url: '/faq',
        },
        {
          title: 'Apparel',
          url:
            'https://teamlocker.squadlocker.com/#/lockers/dynamo-fencing-boston',
        },
      ],
    },
    {
      name: 'Programs',
      links: [
        {
          title: 'Classes',
          url: '/classes',
        },
        {
          title: 'Camps',
          url: '/camps',
        },
        {
          title: 'Birthdays',
          url: '/birthdays',
        },
      ],
    },
    {
      name: 'Media',
      links: [
        {
          title: 'Photos',
          url: '/photos',
        },
        {
          title: 'News',
          url: '/news',
        },
      ],
    },
    {
      name: 'Contact us',
      url: '/contact',
    },
    {
      name: 'Careers',
      url: '/careers',
    },
  ],
  backgroundColor: '#00358e',
  themeColor: '#00358e',
};
