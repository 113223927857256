import styled from 'styled-components';
import media from './media';

const Main = styled.main`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  ${media.thone`max-width: 540px;`};
  ${media.tablet`max-width: 720px;`};
  ${media.desktop`max-width: 960px;`};
  ${media.bigDesktop`max-width: 1140px;`};
`;

export default Main;
